






















import { Component, Vue } from "vue-property-decorator";
import __ from "@/shared/helpers/__";
import PageHeader from "@/admin/components/PageHeader.vue";
import FormBase from "@/shared/classes/form/form-base";
import Field, { FieldSizes } from "@/shared/classes/form/field";
import SearchableField from "@/shared/classes/form/fields/Select/searchable-field";
import { getNodeApiUrlByVersion } from "@/config";
import FormField from "@/shared/components/form/FormField.vue";
import { GlobalActions } from "@/shared/store/global/global.actions";
import { SnackBarTypes } from "@/shared/helpers/snack-bar.helper";
import { CompanyRoutes } from "@/shared/router/company";
import Company from "@/shared/modules/company/company.model";
import { CompanyGetters } from "@/shared/store/company/company.getters";
import EditorField from "@/shared/classes/form/fields/editor-field";
import Form from "@/shared/components/form/Form.vue";
import CompanyTemplateCategoryCreate from "@/company/components/hse-routines/CompanyTemplateCategoryCreate.vue";
import { validateFields } from "@/shared/helpers/validate";

@Component({
  components: { PageHeader, FormField, Form },
  methods: { __ },
})
export default class TemplateRoutineCreate extends Vue {
  form: FormBase = new FormBase()
    .setEndpoint(`${getNodeApiUrlByVersion()}/company-hse-routine-templates`)
    .setUnsetNonFieldsValues(false)
    .setTranslatable(true)
    .setFields([
      new SearchableField()
        .setKey("hseRoutineCategoryUuid")
        .setEntryKey("hseRoutineCategoryUuid")
        .isRequired()
        .setSize(FieldSizes.half)
        .setAddButtonProps({ isIcon: true, openDialog: this.openCreateDialog })
        .setTitle(__("admin.components.routine-templates.form.category"))
        .loadItems({
          endpoint: `${getNodeApiUrlByVersion()}/hse-routines/categories`,
          value: "uuid",
          title: "name",
          perPage: 20,
        })
        .setOnItemSelect((item: any) => this.form.data.category = item?.meta || null),
      new Field()
        .setKey("name")
        .isRequired()
        .setTranslatable(true)
        .setSize(FieldSizes.half)
        .setTitle(__("admin.components.routine-templates.form.routine-title")),
      new EditorField()
        .setKey("content")
        .setTranslatable(true)
        .setTitle(__("admin.components.routine-templates.create-dialog.form.content"))
        .isRequired(),
    ])
    .setSubmit({
      text: __("general.create"),
    })
    .setInjectValues({
      isVisible: true,
      companyUuid: this.company.uuid,
    })
    .setValidate(true, { onlyRegionLangRequired: true })
    .setShowDefaultValidationError(true)
    .setChangeDataBeforeSubmit(data => {
      const body = { ...data };
      if (data.category?.isCompanyCategory) {
        delete body.hseRoutineCategoryUuid;
        body.companyHseRoutineCategoryUuid = data.category.uuid;
      }

      return body;
    })
    .setOnSuccess(this.onSuccess);

  goBack(): void {
    this.$router.push({ name: CompanyRoutes.hseRoutinesIndexTabTemplates });
  }

  private onSuccess(): void {
    this.$store.dispatch(GlobalActions.showSnackBar, {
      type: SnackBarTypes.success,
      message: __("company.views.hse-routines.create.form.on-success"),
    });

    this.goBack();
  }

  openCreateDialog(): void {
    this.$store.dispatch(GlobalActions.showDialog, {
      show: true,
      component: CompanyTemplateCategoryCreate,
      maxWidth: 570,
      persistent: true,
      meta: {
        onSuccess: () => {
          this.$store.dispatch(GlobalActions.closeDialog)
          this.$store.dispatch(GlobalActions.showSnackBar, {
            type: SnackBarTypes.success,
            message: __('company.components.hse-routines.on-success-company'),
          })
        },
      },
    })
  }

  get company(): Company {
    return this.$store.getters[CompanyGetters.getCompany];
  }
}
