
















import { Component, Prop, Vue } from 'vue-property-decorator'
import __ from '@/shared/helpers/__'
import PageHeader from '@/admin/components/PageHeader.vue'
import Form from '@/shared/components/form/Form.vue'
import FormBase from '@/shared/classes/form/form-base'
import { GlobalActions } from '@/shared/store/global/global.actions'
import Field, { FieldSizes } from '@/shared/classes/form/field'
import { HttpMethod } from '@/shared/helpers/requests.helper'
import CompanyHseRoutineCategory from "@/shared/modules/company-hse-routine-categories/company-hse-routine-category.model";
import Company from "@/shared/modules/company/company.model";
import { CompanyGetters } from "@/shared/store/company/company.getters";
import { getNodeApiUrlByVersion } from "@/config";

@Component({
  components: { Form, PageHeader },
  methods: { __ },
})
export default class CompanyTemplateCategoryCreate extends Vue {
  @Prop() meta!: any
  form: FormBase | null = null
  loading: boolean = false

  created() {
    this.form = new FormBase()
      .setEndpoint(`${getNodeApiUrlByVersion()}/company-hse-routine-categories`)
      .setTranslatable(true)
      .setMethod(HttpMethod.POST)
      .setModel(CompanyHseRoutineCategory)
      .setFields([
        new Field()
            .setKey('name')
            .setSize(FieldSizes.full)
            .isRequired()
            .setTranslatable(true)
            .setTitle(__('admin.components.hse.routine-categories.create-dialog.form.name')),
        ])
      .setValidate(true, { onlyRegionLangRequired: true })
      .setShowDefaultValidationError(true)
      .setInjectValues({
        companyUuid: this.company.uuid,
      })
      .setOnSuccess(this.meta.onSuccess)
  }

  async closeDialog() {
    await this.$store.dispatch(GlobalActions.closeDialog)
  }

  get company(): Company {
    return this.$store.getters[CompanyGetters.getCompany];
  }
}
