import Model from '@/shared/classes/model'
import ICompanyHseRoutineCategoryResponse from '@/shared/modules/company-hse-routine-categories/company-hse-routine-category-response.interface'

export default class CompanyHseRoutineCategory extends Model<ICompanyHseRoutineCategoryResponse> {
  name!: string
  companyUuid!: string

  transform(data: ICompanyHseRoutineCategoryResponse): void {
    super.transform(data)

    this.name = data.name
    this.companyUuid = data.companyUuid
  }
}
